<script>
	import { createEventDispatcher } from 'svelte';
	import { appStores } from '$lib/stores/appStores';
	import { Translator } from '$lib/js/translations/Translator';
	import { v4 } from 'uuid';

	const dispatch = createEventDispatcher();

	const T = new Translator($appStores.language, [
		// Page translations
		{
			en: 'Drop file here or click to select',
			'zh-tw': '將檔案拖曳到此處或按一下選擇',
		},
		{
			en: 'Selected',
			'zh-tw': '已選擇',
		},
	]);

	import FilePlusIcon from '$lib/components/Icons/FilePlusIcon.svelte';
	import { truncateText } from '$lib/js/text_utils';
	import DocumentIcon from '$lib/components/Icons/DocumentIcon.svelte';

	export let label = undefined;
	export let placeholder = undefined;
	export let required = false;
	export let selectedFile = undefined;
	export let accept = '*/*';

	let id = `file-dropzone-${v4()}`;
	let fileInput = undefined;

	export const getFile = () => {
		return selectedFile;
	};

	export const clearFile = () => {
		selectedFile = undefined;
		fileInput.value = '';
	};

	export const readFile = () => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = () => resolve(undefined);
			reader.readAsArrayBuffer(selectedFile);
		});
	};

	const handleFileInput = (event) => {
		selectedFile = event.target.files[0];
	};

	const handleDrop = (event) => {
		event.preventDefault();
		selectedFile = event.dataTransfer.files[0];
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};
</script>

{#if label}
	<div class="block pb-1 text-sm font-medium">
		{label}
		{#if required}<span class="text-red-500">*</span>{/if}
	</div>
{/if}

<div
	{id}
	class="dropzone w-full cursor-pointer rounded-lg border border-dashed text-center hover:bg-gray-50"
	class:border-gray-300={selectedFile == undefined}
	class:border-indigo-500={selectedFile != undefined}
	on:drop={handleDrop}
	on:dragover={handleDragOver}
	on:click={() => {
		fileInput.click();
	}}
>
	<div
		class="flex flex-row items-center justify-center gap-2 py-4"
		class:text-gray-300={selectedFile == undefined}
		class:text-indigo-500={selectedFile != undefined}
	>
		<div class="grid content-center">
			{#if selectedFile}
				<DocumentIcon class="h-10 w-10" />
			{:else}
				<FilePlusIcon class="h-10 w-10" />
			{/if}
		</div>
		<div class="grid content-center">
			{#if selectedFile}
				{T.out('Selected')} {truncateText(selectedFile.name, 25)}
			{:else if placeholder}
				{placeholder}
			{:else}
				{T.out('Drop file here or click to select')}
			{/if}
		</div>
	</div>
</div>

<input bind:this={fileInput} type="file" on:change={handleFileInput} style="display: none;" accept={accept ?? '*/*'} />
